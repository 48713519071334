export const langchainpage = [
    {
        path: '/langchainpage_1.1',
        name: 'langchainpage_1.1',
        component: () => import('../views/PageContent/LangchainContent/langchain1.1')
    },
    {
        path: '/langchainpage_1.2',
        name: 'langchainpage_1.2',
        component: () => import('../views/PageContent/LangchainContent/langchain1.2')
    },
]