export const weekroutes = [
    {
        path: '/weekpage_1.1',
        name: 'weekpage_1.1',
        component: () => import('../views/PageContent/WeekContent/week1.1')
    },
    {
        path: '/weekpage_1.2',
        name: 'weekpage_1.2',
        component: () => import('../views/PageContent/WeekContent/week1.2')
    },
    {
        path: '/weekpage_1.3',
        name: 'weekpage_1.3',
        component: () => import('../views/PageContent/WeekContent/week1.3')
    },
    {
        path: '/weekpage_1.4',
        name: 'weekpage_1.4',
        component: () => import('../views/PageContent/WeekContent/week1.4')
    },
    {
        path: '/weekpage_1.5',
        name: 'weekpage_1.5',
        component: () => import('../views/PageContent/WeekContent/week1.5')
    },
    {
        path: '/weekpage_1.6',
        name: 'weekpage_1.6',
        component: () => import('../views/PageContent/WeekContent/week1.6')
    },
]